@import "_index.less";

main.main {
	>section.main-section {
		>.section-center {
			>.block-banner {
			    background-color: #111;
			    padding: 75px 0 100px 0;
				h1 {
			        margin: 0;
			        color: #fff;
			        font-weight: bold;
			        text-align: center;
			        text-transform: uppercase;
			        .font-sizeXXL();
			        >b {
			            color: @colorPrimary;
			        }
			        >span {
			            color: #ddd;
			            .font-sizeXL();
			            >b {
			               color: @colorPrimary; 
			            }
			        }
				}
				.block-trans-icons {
			        text-align: center;
			        margin-top: 40px;
			        margin-bottom: -26px;
				    >.trans-icon {    
				        position: relative;
				        display: inline-block;
			            text-align: center;
			            width: 100px;
			            >i {
			                color: @colorPrimary;
			                .font-sizeXXL();
			            }
			            >span {
							position: absolute;
							left: 0;
							right: 0;
							bottom: -25px;
			                .font-sizeSM();
							color: #ddd;
							text-transform: uppercase;
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow: hidden;
			            }
				    }
				}
			}
			>.block-adverts {
			    padding: 75px 0;
			    .card {
			    	>img {
						width: initial;
						max-width: 100%;
						min-height: 200px;
						max-height: 200px;
						margin: 0 auto;
			    	}
			    	>.card-body {
						min-height: 100px;
						max-height: 100px;
			    	}
			    }
			}
		}
	}
}





.block-location {
    background-color: #111;
    padding: 75px 0;
	h1 {
        margin: 0 0 25px 0;
        color: #fff;
        font-weight: bold;
        text-align: center;
        font-size: 28px;
        text-transform: uppercase;
	}
	.regions {
		.region-name {
		    display: block;
		    color: @colorPrimary;
		    padding: 5px;
		    >a {
		    	color: @colorPrimary;	
		    }
		    >.fa {
		    	float: right;
		    	cursor: pointer;
		    }
		}
		.provinces {
			.province-name {
				display: inline-block;
				margin-right: 5px;
				font-size: 12px;
				color: #f0f0f0;
			}
		}
	}
}
.block-adbygoogle {
    text-align: center;
    background-color: #ddd;  
}