/*|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||*\
|||||  Web Agency SC-CONCEPTION ---------------------- sc-conception.com  |||||
|||||  Powered by cms Iminence.com software --------------- iminence.com  |||||
\*|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||*/

@fontSizeXXL: 2.0;
@fontSizeXL: 1.6;
@fontSizeLG: 1.4;
@fontSizeMD: 1;
@fontSizeSM: .9 ;
@fontSizeXS: .8;
@fontSizeXXS: .7;
.font-sizeXXL(@sizeValue: @fontSizeXXL) { .font-size(@sizeValue); }
.font-sizeXL(@sizeValue: @fontSizeXL) { .font-size(@sizeValue); }
.font-sizeLG(@sizeValue: @fontSizeLG) { .font-size(@sizeValue); }
.font-sizeMD(@sizeValue: @fontSizeMD) { .font-size(@sizeValue); }
.font-sizeSM(@sizeValue: @fontSizeSM) { .font-size(@sizeValue); }
.font-sizeXS(@sizeValue: @fontSizeXS) { .font-size(@sizeValue); }
.font-sizeXXS(@sizeValue: @fontSizeXXS) { .font-size(@sizeValue); }
.font-size(@sizeValue) {
    @remValue: @sizeValue;
    @pxValue: (@sizeValue * 10);
    font-size: ~"@{pxValue}px"; 
    font-size: ~"@{remValue}rem";
}
.transition(@duration:all 333ms, @ease:ease-out) {
  -webkit-transition: @duration @ease;
  -moz-transition: @duration @ease;
  -o-transition: @duration @ease;
  transition: @duration @ease;
}
.opacity(@opacity: 0.5) {
  -moz-opacity: @opacity;
  -khtml-opacity: @opacity;
  -webkit-opacity: @opacity;
  opacity: @opacity;
  @opperc: @opacity * 100;
  -ms-filter: ~"progid:DXImageTransform.Microsoft.Alpha(opacity=@{opperc})";
  filter: ~"alpha(opacity=@{opperc})";
}

@fontFamily: 'Open Sans', sans-serif;
@fontFamilyTitle: 'Oswald', sans-serif;
@fontFamilyCondensed: 'Oswald', sans-serif;

@host:'https://www.bonfilon.fr';

@fontWeightLight:300;
@fontWeightNormal:500;
@fontWeightBold:700;
@fontWeightExtraBold:700;

@colorPrimary:#f7931e;
@colorOnColorPrimary:#fff;
@colorSecondary:#111;
@colorOnColorSecondary:#fff;